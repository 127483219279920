import { v4 as uuidv4 } from 'uuid';
import log from 'loglevel';
import { PanelService } from '../consultation/Panels/PanelService';
import IdentificationService from '../consultation/Api/IdentificationService';
import LocalizationService from '../consultation/LocalizationService';
import { getSortedOptions } from '../consultation/Options/getSortedOptions';
import { i18n } from '../i18n/i18n';
import { ConsentAnswerData } from '../consultation/AnswerData/ConsentAnswerData';
import { SurveyAnswerData } from '../consultation/AnswerData/SurveyAnswerData';
import { DiscreteChoiceAnswerData } from '../consultation/AnswerData/DiscreteChoiceAnswerData';
import { CompletionAnswerData } from '../consultation/AnswerData/CompletionAnswerData';
import { ChoiceTaskAnswerData } from '../consultation/AnswerData/ChoiceTaskAnswerData';
import { ChoiceTaskMotivationAnswerData } from '../consultation/AnswerData/ChoiceTaskMotivationAnswerData';
import { isObject } from '../utils/isObject';
import { calculatePropertyValues } from '../consultation/Properties/calculatePropertyValues';
import { Option } from '../types/Option';
import { Property } from '../types/Property';
import { hideCurrentEntity } from "../consultation/Conditions/hideCurrentEntity";
import { Stage } from "../types/Stage";
import { defineStore } from 'pinia';
import { ConsultationData } from '../types/ConsultationData';

interface State {
  locale: string;
  globalData: ConsultationData | null;
  sessionId: string | null;
  answerData: Object;
  clientToken: string | null;
  isIdentificationError: boolean | null;
  stageIndex: number;
  sortingProperty: number | null;
  completed: boolean;
  nextDisabled: boolean;
  navigate: string;
  showOptionDetailModal: boolean;
  showExceededRestrictionsModal: boolean;
  showExceededWarningsModal: boolean;
  showPointsLeftModal: boolean;
  showTargetNotReachedModal: boolean;
  showTargetOverReachedModal: boolean;
  inCompareMode: boolean;
  skipChoiceTask: boolean;
  warningData: Object;
  showWarning: boolean,
  confirmData: Object;
  showConfirm: boolean;
  panelParams: any;
  helpData: Object | null;
  showHelp: boolean;
  cookie: string;
  privacyStatement: string | null;
  showPrivacyStatement: boolean;
  accessibilityInfo: string | null;
  showAccessibilityInfo: boolean;
  infoMode: string | null;
  browserBackConfirm: boolean;
}

export const useConsultationStore = defineStore('ConsultationStore', {
  state: (): State => ({
    completed: false,
    stageIndex: 0,
    navigate: 'forward',
    nextDisabled: false,
    showOptionDetailModal: false,
    showExceededRestrictionsModal: false,
    showExceededWarningsModal: false,
    showPointsLeftModal: false,
    showTargetNotReachedModal: false,
    showTargetOverReachedModal: false,
    inCompareMode: false,
    sortingProperty: null,
    skipChoiceTask: false,
    panelParams: null,
    answerData: {},
    globalData: null,
    locale: 'nl',
    sessionId: null,
    clientToken: null,
    isIdentificationError: null,
    confirmData: {},
    showConfirm: false,
    helpData: null,
    showHelp: false,
    warningData: {},
    showWarning: false,
    cookie: '',
    privacyStatement: null,
    showPrivacyStatement: false,
    accessibilityInfo: null,
    showAccessibilityInfo: false,
    infoMode: null,
    browserBackConfirm: false,
  }),
  getters: {
    stage: ({ globalData, stageIndex }) => globalData.stages[stageIndex],

    stageId: ({ globalData, stageIndex }) => globalData.stages[stageIndex].id,

    stages: ({ globalData }) => globalData.stages,

    stagesCount: ({ globalData }) => globalData.stages.length,

    isInstanceActive: ({ globalData }) => Boolean(globalData.settings.is_active),

    hasFeedback: ({ globalData }) => Boolean(globalData.settings.has_feedback_button),

    getInstanceId: ({ globalData }) => globalData.id,

    getInstanceStatus: ({ globalData }) => globalData.status,

    getAnswerData({ answerData }) {
      return answerData;
    },

    consentGiven({ answerData }) {
      for (const [key, answers] of Object.entries(answerData)) {
        if (key.includes('consent') && answers.answers.answer_id === 'accept') {
          return true;
        }
      }

      return false;
    },

    getAvailableLocales({ globalData }) {
      return globalData.settings.locales
    },

    getPrivacyStatement: ({ globalData }) => globalData.settings.privacy,

    getAccessibilityInfo: ({ globalData }) => globalData.settings.accessibility,

    canNavigateBack({ stageIndex, globalData }) {
      if (stageIndex === this.stagesCount - 1) {
        return false;
      }

      if (stageIndex === 1 && globalData.stages[0].id === 'language-selection') {
        return false;
      }

      return stageIndex > 0;
    },

    canNavigateForward({ stageIndex }) {
      if (stageIndex === this.stagesCount - 1) {
        const fields = this.stage.fields;
        if (fields.panelRedirectUrl || fields.nonPanelRedirectUrl) {
          return true;
        }
      }

      return stageIndex < this.stagesCount - 1;
    },

    getInstanceSlug({ globalData }) {
      return globalData.slug.toLowerCase();
    },

    getWikiContent({ globalData }) {
      return globalData.wikis;
    },

    isRtlScript({ locale }) {
      return LocalizationService.isRtlScript(locale);
    },

    getPanelMode: () => {
      return localStorage.getItem('wevaluate.panel_id') !== null;
    },

    getBlockId() {
      return this.stage.blockId
    },

    inChoiceTask() {
      return this.stageId.includes('choice-task');
    },

    getProjectTitle({ globalData }) {
      return globalData !== null ? globalData.title : false;
    },

    getHelpData({ globalData }) {
      return globalData.settings.help
    },

    getSequenceNumber({ stageIndex }) {
      return stageIndex + 1;
    },

    totalSpendPoints() {
      const answerData = this.getAnswerData[this.stageId].answers;

      if (!answerData) {
        return 0;
      }

      return Object.values(answerData).reduce(
        (acc, { quantity }) => acc + quantity,
        0
      );
    },

    consultationTheme: ({ globalData }) => {
      if (globalData === null) {
        return {};
      }

      return globalData.theme || {};
    },

    getDocumentTitle: ({ globalData, locale }) => {
      if (globalData.title && typeof globalData.title === 'object') {
        const title = globalData.title[locale] || globalData.title[globalData.settings.default_locale] || globalData.title[Object.keys(globalData.title)[0]];
        return title + ' - Wevaluate';
      }

      return 'Wevaluate';
    },

    stageOptions({ sortingProperty }) {
      const options = this.stage.fields.options;

      if (sortingProperty === null) {
        return options;
      }

      return getSortedOptions(options, sortingProperty, this.stageProperties);
    },

    stageProperties() {
      return this.stage.fields.properties;
    },

    stagePointsData() {
      return this.stage.fields.pointsData;
    },

    isPointsMode() {
      return this.stage.fields.mode === 'points';
    },

    isPickMode() {
      return this.stage.fields.mode === 'pick';
    },

    isSliderMode() {
      return this.stage.fields.mode === 'slide_to_max';
    },

    isSymmetricalSlider() {
      return this.isSliderMode && this.stage.fields.slideToMaxData.is_symmetrical;
    },

    getCustomFooterText({ globalData }) {
      return globalData.settings.footer_text
    },

    getPanelSource() {
      return localStorage.getItem('wevaluate.panel_source');
    },

    getPanelId() {
      return JSON.parse(localStorage.getItem('wevaluate.panel_id'));
    },

    getInstanceData({ locale, sessionId, clientToken, globalData }) {
      let panelId = null;

      const panelParams = this.getPanelId;
      if (typeof panelParams === 'string') {
        panelId = panelParams;
      } else if (isObject(panelParams) && Object.keys(panelParams).length === 1) {
        panelId = Object.values(panelParams)[0];
      } else if (isObject(panelParams) && Object.keys(panelParams).length > 1) {
        panelId = JSON.stringify(panelParams);
      }

      return {
        instanceStatus: this.getInstanceStatus,
        instanceId: this.getInstanceId,
        isPanelUser: this.getPanelMode,
        language: locale,
        panelId: panelId,
        panelSource: this.getPanelSource,
        userClientToken: clientToken,
        userSessionId: sessionId,
      }
    },

    isPanelParticipant({ panelParams }) {
      return panelParams !== null;
    },

    getPanelRejectLink({ panelParams, globalData }) {
      if (this.isPanelParticipant === false) {
        return false;
      }

      const result = PanelService.computeRedirectUrl(
        globalData.settings.panel_reject_url,
        panelParams
      );

      return result;
    },

    getPanelRedirectLink({ panelParams }) {
      if (this.stageIndex === this.stagesCount - 1) {
        const fields = this.stage.fields;

        if (fields.panelRedirectUrl) {
          return PanelService.computeRedirectUrl(
            fields.panelRedirectUrl,
            panelParams
          );
        }
      }

      return false;
    },

    getBlockConditions() {
      const blockConditions = {};

      this.stages.forEach((stage: Stage) => {
        if (stage.conditions && stage.conditions.length > 0) {
          blockConditions[stage.blockId] = stage.conditions;
        }
      });

      return blockConditions;
    },

    getQuestionConditions() {
      const questionConditions = {};

      this.stages.forEach((stage: Stage) => {
        if (!stage.fields.questions) {
          return;
        }

        stage.fields.questions.forEach((question) => {
          if (question.conditions && question.conditions.length > 0) {
            questionConditions[question.id] = question.conditions;
          }

          if (question.field_type === 'matrix' && question.matrix_theses) {
            question.matrix_theses.forEach((theses) => {
              if (theses.conditions && theses.conditions.length > 0) {
                questionConditions[theses.id] = theses.conditions;
              }
            });
          }
        });
      });

      return questionConditions;
    },
  },
  actions: {
    async init(consultation: ConsultationData) {
      this.globalData = consultation;
      this.sessionId = uuidv4();

      const locales = Object.keys(consultation.settings.locales);
      for (const locale of locales) {
        i18n.global.mergeLocaleMessage(locale, consultation.labels[locale]);
      }
      this.setLocale(consultation.settings.default_locale);

      PanelService.unsetPanelUser();
      if (consultation.settings.is_panel) {
        const panelParams = PanelService.getPanelParams(window.location.search);
        this.panelParams = panelParams;
      }

      const answerData = {};
      consultation.stages.forEach((stage) => {
        const answers = {};

        if (stage.stageType === 'choice-task') {
          stage.fields.options.forEach((option) => {
            answers[option.id] = { ...option, quantity: option.initial_quantity };
          });
        }

        const data = {
          blockId: stage.blockId,
          answers: answers,
          versionId: null,
          uuid: null,
          meta: {},
        };

        if (stage.stageType === 'choice-task' || stage.stageType === 'discrete-choice') {
          data.versionId = stage.fields.version;
        }

        answerData[stage.id] = data;
      });

      this.answerData = answerData;

      const requireIdentificationToken = consultation.stages.some(({ blockType }) => blockType === 'consent' || blockType === 'research' || blockType === 'questions' || blockType === 'dce');
      if (requireIdentificationToken) {
        this.setClientIdentification();
      }

      const urlParams = new URLSearchParams(window.location.search);
      const deepLink = urlParams.get('__wvdeeplink');

      if (consultation.settings.is_active === false && deepLink) {
        const payload = JSON.parse(window.atob(deepLink));

        const index = consultation.stages.findIndex((stage) => {
          if (payload.question_id !== undefined && stage.stageType === 'survey') {
            const questions = stage.fields.questions;

            return questions.some((question) => question.id === payload.question_id);
          }

          if (payload.page_id !== undefined) {
            return stage.stageId === payload.page_id && stage.segmentType === 'Page';
          }

          if (payload.parent_segment_id !== undefined && payload.parent_segment_id === stage.blockId) {
            return payload.segment_id === stage.stageId;
          }

          return stage.blockId === payload.segment_id;
        });

        if (index !== -1) {
          this.stageIndex = index;
        }
      }
    },

    setLocale(locale: string) {
      this.locale = locale;
      i18n.global.locale = locale;
      LocalizationService.setLanguageAttribute(locale)
      LocalizationService.setWriteDirection(locale)
    },

    setDocumentTitle() {
      if (this.stage.fields.title === undefined) {
        return;
      }

      let pageTitle = this.stage.fields.title[this.locale];

      if (pageTitle === undefined) {
        const defaultLocale = this.globalData.settings.default_locale;
        pageTitle = this.stage.fields.title[defaultLocale];
      }

      document.title = pageTitle + ' - Wevaluate';
    },

    async setClientIdentification() {
      try {
        const token = await IdentificationService.getClientToken();
        this.clientToken = token;
      } catch (error) {
        this.isIdentificationError = true;
        log.error(error);
      }
    },

    increaseStageIndex() {
      const index = this.stageIndex + 1;

      for (let i = index; i < this.globalData.stages.length; i++) {
        const stage = this.globalData.stages[i];
        if (stage.hidden === true) {
          continue;
        }

        if (stage.stageType === 'survey') {
          const visibleQuestions = stage.fields.questions.filter((question) => question.hidden === false);

          if (visibleQuestions.length === 0) {
            continue;
          }
        }

        if (stage.stageType === 'motivate-selection') {
          const id = `choice-task-${stage.blockId}`;
          const answers = this.getAnswerData[id].answers;
          const selectedOptions = stage.fields.options.filter((option) => answers[option.id].quantity > 0);

          if (selectedOptions.length === 0 && stage.fields.openMotivation === false) {
            continue;
          }
        }

        if (stage.stageType === 'motivate-non-selected') {
          const id = `choice-task-${stage.blockId}`;
          const answers = this.getAnswerData[id].answers;
          const nonSelectedOptions = stage.fields.options.filter((option) => answers[option.id].quantity === 0);

          if (nonSelectedOptions.length === 0) {
            continue;
          }
        }

        this.stageIndex = i;
        break;
      }
    },

    decreaseStageIndex() {
      const index = this.stageIndex - 1;

      for (let i = index; i >= 0; i--) {
        const stage = this.globalData.stages[i];

        if (stage.hidden === true) {
          continue;
        }

        if (stage.stageType === 'survey') {
          const visibleQuestions = stage.fields.questions.filter((question) => question.hidden === false);

          if (visibleQuestions.length === 0) {
            continue;
          }
        }

        if (stage.stageType === 'motivate-selection') {
          const id = `choice-task-${stage.blockId}`;
          const answers = this.getAnswerData[id].answers;
          const selectedOptions = stage.fields.options.filter((option) => answers[option.id].quantity > 0);

          if (selectedOptions.length === 0 && stage.fields.openMotivation === false) {
            continue;
          }
        }

        if (stage.stageType === 'motivate-non-selected') {
          const id = `choice-task-${stage.blockId}`;
          const answers = this.getAnswerData[id].answers;
          const nonSelectedOptions = stage.fields.options.filter((option) => answers[option.id].quantity === 0);

          if (nonSelectedOptions.length === 0) {
            continue;
          }
        }

        this.stageIndex = i;
        break;
      }
    },

    async next() {
      if (this.nextDisabled) {
        return;
      }

      if (this.stage.blockType === 'consent' && this.consentGiven === false && !this.completed) {
        const confirmData = {
          title: i18n.global.t('pagination.dropout_msg_title'),
          message: i18n.global.t('pagination.dropout_msg'),
          typeOfConfirm: 'confirmDeclineConsent',
        };

        this.confirmData = confirmData;
        this.showConfirm = true;
        return;
      }

      if (this.completed && this.stage.blockType === 'consent' && this.consentGiven === false && this.getPanelRejectLink) {
        return window.open(this.getPanelRejectLink, '_self');
      }

      if (this.stageIndex === this.stagesCount - 1) {
        const stage = this.stage;

        if (this.isPanelParticipant && this.getPanelRedirectLink) {
          return window.open(this.getPanelRedirectLink, '_self');
        }

        if (!this.isPanelParticipant && stage.fields.nonPanelRedirectUrl) {
          return window.open(stage.fields.nonPanelRedirectUrl, '_self');
        }
      }

      if (this.canNavigateForward) {
        this.navigate = 'forward';
        this.increaseStageIndex();
        this.setDocumentTitle();
      }
    },

    async back() {
      if (this.canNavigateBack) {
        this.navigate = 'backward';
        this.decreaseStageIndex();
        this.setDocumentTitle();
      }
    },

    processBlockConditions() {
      const blockConditions = this.getBlockConditions;
      const answers = this.getAnswerData;
      const blocksToHide = [];

      for (const [blockId, conditions] of Object.entries(blockConditions)) {
        if (conditions.length === 0) {
          continue;
        }

        const parsedBlockId = parseInt(blockId);
        const addBlockIdToHide = hideCurrentEntity(conditions, answers);

        if (addBlockIdToHide) {
          blocksToHide.push(parsedBlockId);
        }
      }

      this.hideBlocks(blocksToHide);
    },

    processQuestionConditions() {
      const questionConditions = this.getQuestionConditions;
      const answers = this.getAnswerData;
      const questionsToHide = [];

      for (const [questionId, conditions] of Object.entries(questionConditions)) {
        if (conditions.length === 0) {
          continue;
        }

        const parsedQuestionId = parseInt(questionId);
        const addQuestionIdToHide = hideCurrentEntity(conditions, answers);

        if (addQuestionIdToHide) {
          questionsToHide.push(parsedQuestionId);
        }
      }

      this.hideQuestions(questionsToHide);
    },

    processConditions() {
      this.processBlockConditions();
      this.processQuestionConditions();
    },

    hideQuestions(questionsToHide) {
      for (const stage of this.stages) {
        if (stage.blockType !== 'questions') {
          continue;
        }

        for (const question of stage.fields.questions) {
          question.hidden = questionsToHide.includes(question.id);

          if (question.field_type === 'matrix') {
            for (const theses of question.matrix_theses) {
              theses.hidden = questionsToHide.includes(theses.id);
            }
          }
        }
      }
    },

    hideBlocks(blocksToHide) {
      for (const stage of this.stages) {
        stage.hidden = blocksToHide.includes(stage.blockId);
      }
    },

    calculateOptionEffects() {
      const stage = this.stage;
      const answerData = this.getAnswerData[this.stageId].answers;
      const selectedOptions: Option[] = Object.values(answerData);

      this.stageProperties.forEach((property: Property) => {
        calculatePropertyValues(property, selectedOptions, stage);
      });

      const effects = {}

      this.stageProperties.forEach((property: Property) => {
        let effect: any = property.current_calculated_value_exceeds_restriction;

        if (property.restrictive === false) {
          effect = false
        } else if (property.current_calculated_value_exceeds_restriction === null) {
          effect = 'pass';
        }

        effects[property.id] = {
          value: property.current_calculated_value,
          restriction: effect,
        };
      });

      this.setAnswerMeta({
        stageId: this.stageId,
        data: { effects },
      });
    },

    setAnswerData(data) {
      const answerData = this.answerData[data.stageId];

      this.answerData[data.stageId] = {
        ...answerData,
        answers: data.data,
      }
    },

    setAnswerMeta(data) {
      const answerData = this.answerData[data.stageId];

      this.answerData[data.stageId] = {
        ...answerData,
        meta: data.data,
      }
    },

    async submitCompletionAnswerData(stageId) {
      const blockId = this.getAnswerData[stageId].blockId;
      const instanceData = this.getInstanceData;
      const answerData = new CompletionAnswerData(blockId, instanceData, this.getAnswerData)
      await answerData.submit();
    },

    async submitChoiceTaskAnswerData(stageId) {
      const blockId = this.getAnswerData[stageId].blockId;
      const instanceData = this.getInstanceData;
      const answerData = new ChoiceTaskAnswerData(blockId, instanceData, this.getAnswerData)
      await answerData.submit();
    },

    async submitChoiceTaskMotivationAnswerData(stageId) {
      const blockId = this.getAnswerData[stageId].blockId;
      const instanceData = this.getInstanceData;
      const answerData = new ChoiceTaskMotivationAnswerData(blockId, instanceData, this.getAnswerData)
      await answerData.submit();
    },

    async submitDiscreteChoiceAnswerData(stageId) {
      const blockId = this.getAnswerData[stageId].blockId;
      const instanceData = this.getInstanceData;
      const answerData = new DiscreteChoiceAnswerData(blockId, instanceData, this.getAnswerData)
      await answerData.submit();
    },

    async submitSurveyAnswerData(stageId) {
      const blockId = this.getAnswerData[stageId].blockId;
      const instanceData = this.getInstanceData;
      const answerData = new SurveyAnswerData(blockId, instanceData, this.getAnswerData)
      await answerData.submit();
    },

    async submitConsentAnswerData(stageId) {
      const blockId = this.getAnswerData[stageId].blockId;
      const instanceData = this.getInstanceData;
      const answerData = new ConsentAnswerData(blockId, instanceData, this.getAnswerData)
      await answerData.submit();
    },

    // // TODO: Make unique to consent declined
    functionConfirmButtonDialog() {
      if (this.stage.blockType === 'consent') {
        this.completed = true;
        return;
      }
    },

    setUserLeavesConfirmData() {
      const confirmData = {
        title: i18n.global.t('pagination.dropout_msg_title'),
        message: i18n.global.t('pagination.warning_message_browser_back'),
        typeOfConfirm: 'confirmUserLeaves',
      };

      this.confirmData = confirmData;
    },
  },
});
