<template>
  <div
    class="page-container"
  >
    <div class="card page survey-page">
      <section
        v-if="!getMobileView"
        class="navigation-wrapper row mb-4 ms-0 me-0"
      >
        <AppNavigation
          :navigation-panel-top="true"
        />
      </section>
      <div>
        <div class="page-title">
          <h1 v-show="title">
            <LanguageSelector :to-be-selected="title" />
          </h1>
        </div>
        <div
          v-if="subtitle"
          class="page-subheader"
        >
          <LanguageSelector :to-be-selected="subtitle" />
        </div>
        <div class="page-questions mt-4">
          <TransitionGroup
            name="list"
            tag="div"
          >
            <component
              :is="question.component"
              v-for="question in questions"
              ref="questions"
              :key="question.id"
              :current-question-data="question"
              :show-user-input-is-required="showUserInputRequired(question.id)"
              :current-question-number="returnCurrentQuestionNumber(question.id)"
              :current-question-display-number="
                returnCurrentQuestionNumber(question.id) + 1
              "
              :total-question-number="returnTotalQuestionNumber()"
              :current-user-data="currentUserDataQuestions(question.id)"
              :validation-label="requiredMessageForQuestion(question)"
              class="question-component"
              @send-current-user-data="updateData"
            />
          </TransitionGroup>
        </div>
      </div>
      <div class="page-navigation">
        <AppNavigation :submit-function="submit" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InputTypeText from './InputTypeText.vue';
import InputTypeTextArea from './InputTypeTextArea.vue';
import InputTypeTextAreaLarge from './InputTypeTextAreaLarge.vue';
import InputTypeRadioTable from './InputTypeRadioTable.vue';
import InputTypeDropdown from './InputTypeDropdown.vue';
import InputTypeMatrix from './InputTypeMatrix.vue';
import InputTypeSlider from './InputTypeSlider.vue';
import InputTypeCheckboxTable from './InputTypeCheckboxTable.vue';
import InputTypeRadioAb from './InputTypeRadioAb.vue';
import InputTypeTextSection from './InputTypeTextSection.vue';
import InputTypeDragInOrder from './InputTypeDragInOrder.vue';
import AppNavigation from './AppNavigation.vue';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';
import { mapWritableState, mapState } from 'pinia';

export default defineComponent({
  components: {
    InputTypeText,
    InputTypeTextArea,
    InputTypeTextAreaLarge,
    InputTypeRadioAb,
    InputTypeRadioTable,
    InputTypeDropdown,
    InputTypeMatrix,
    InputTypeSlider,
    InputTypeCheckboxTable,
    InputTypeTextSection,
    InputTypeDragInOrder,
    AppNavigation,
  },

  setup() {
    const consultationStore = useConsultationStore();
    const interfaceStore = useInterfaceStore();

    return {
      getMobileView: interfaceStore.getMobileView,
      stage: consultationStore.stage,
      stageId: consultationStore.stageId,
      getAnswerData: consultationStore.getAnswerData,
      setAnswerData: consultationStore.setAnswerData,
      submitSurveyAnswerData: consultationStore.submitSurveyAnswerData,
      processConditions: consultationStore.processConditions,
    };
  },

  data() {
    return {
      alertUser: false,
    }
  },

  computed: {
    ...mapWritableState(useConsultationStore, [
      'nextDisabled',
    ]),

    ...mapState(useConsultationStore, [
      'locale',
    ]),

    title() {
      return this.stage.fields.title;
    },

    subtitle() {
      return this.stage.fields.subtitle;
    },

    questions() {
      return this.stage.fields.questions.filter((question) => question.hidden !== true);
    },
  },

  created() {
    this.nextDisabled = this.isNextDisabled();
  },

  methods: {
    submit() {
      let unansweredQuestion = undefined;
      for (const [index, question] of this.questions.entries()) {
        const answers = this.currentUserDataQuestions(question.id);

        if (question.field_type !== 'text_section' && question.required && !answers.user_has_answered) {
          unansweredQuestion = index;
          break;
        }
      }

      if (unansweredQuestion !== undefined) {
        this.alertUser = true;
        this.$refs['questions'][unansweredQuestion].$el.scrollIntoView(true);
        return;
      }

      this.submitSurveyAnswerData(this.stageId);
    },

    updateData(data) {
      const answerData = this.getAnswerData[this.stageId] ? this.getAnswerData[this.stageId].answers : {};

      answerData[data.id] = data;

      const payload = {
        stageId: this.stageId,
        data: answerData
      };

      this.setAnswerData(payload);
      this.processConditions();
      this.nextDisabled = this.isNextDisabled();
    },

    requiredMessageForQuestion(question) {
      if (question.messages && question.messages[this.locale] && question.messages[this.locale].required) {
        return question.messages[this.locale].required;
      }

      return this.$t('pagination.input_validation_required');
    },

    currentUserDataQuestions(id) {
      const answerData = this.getAnswerData[this.stageId].answers;
      return answerData[id] || {};
    },

    isNextDisabled() {
      for (const question of this.questions) {
        const answers = this.currentUserDataQuestions(question.id);

        if (question.field_type !== 'text_section' && question.required && !answers.user_has_answered) {
          return true
        }
      }

      return false;
    },

    showUserInputRequired(id) {
      const { required, user_has_answered } = this.currentUserDataQuestions(id);

      if (this.alertUser && required && !user_has_answered) {
        return true;
      }

      return false;
    },

    returnTotalQuestionNumber() {
      const filtered = this.questions.filter(
        (question) =>
          question.field_type !== 'text_section' &&
          question.field_type !== 'pagebreak'
      );

      return filtered.length;
    },

    returnCurrentQuestionNumber(id) {
      let currentNumber = 0;
      const questionData = this.questions;

      for (let i = 0; i < questionData.length; i++) {
        if (
          questionData[i].field_type !== 'text_section' &&
          questionData[i].field_type !== 'pagebreak'
        ) {
          if (questionData[i].id === id) {
            return currentNumber;
          } else {
            currentNumber++;
          }
        }
      }
    },
  },
});
</script>

<style scoped>
.question-component {
  transition: all 0.2s ease-out;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.list-leave-active {
  position: absolute;
  display: none;
}
</style>
