import { createI18n } from "vue-i18n";
import { en } from "./en";
import { es } from "./es";
import { nl } from "./nl";

export const i18n = createI18n({
  locale: 'nl',
  legacy: false,
  fallbackLocale: 'nl',
  messages: {
    en,
    es,
    nl,
  },
})
