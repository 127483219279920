<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n'
import Cookies from 'js-cookie';
import InputTypeRadioTable from './InputTypeRadioTable.vue';
import AppNavigation from './AppNavigation.vue';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useAnalyticsStore } from '../stores/useAnalyticsStore';
import { useConsultationStore } from '../stores/useConsultationStore';
import CardPage from './CardPage.vue';

const { t } = useI18n()
const interfaceStore = useInterfaceStore();
const analyticsStore = useAnalyticsStore();
const consultationStore = useConsultationStore();

const isCookie = ref(false);
const showUserInputIsRequired = ref(false);

const getMobileView = computed(() => interfaceStore.getMobileView);
const nextDisabled = computed({
  get: () => consultationStore.nextDisabled,
  set: (value) => (consultationStore.nextDisabled = value),
});

const title = computed(() => consultationStore.stage.fields.title);
const content = computed(() => consultationStore.stage.fields.content);
const currentUserData = computed(() => {
  return consultationStore.getAnswerData[consultationStore.stageId]?.answers || {};
});

const consentOptions = computed(() => {
  const locale = consultationStore.locale;
  const answerOptions = [
    {
      id: 'accept',
      title: { [locale]: t('pagination.accept_terms') },
    },
    {
      id: 'decline',
      title: { [locale]: t('pagination.decline_terms') },
    },
  ];

  return {
    id: 'consent',
    answer_options: answerOptions,
    required: true,
    field_type: 'radio_table',
  };
});

const submit = () => {
  if (currentUserData.value.user_has_answered === false) {
    showUserInputIsRequired.value = true;
    return;
  }

  consultationStore.submitConsentAnswerData(consultationStore.stageId);
  analyticsStore.trackUserAction([
    `Submit consent: ${consultationStore.consentGiven}`,
    consultationStore.getBlockId,
    consultationStore.getSequenceNumber,
  ]);
};

const getCookie = async () => {
  if (!consultationStore.isInstanceActive) {
    return false;
  }

  const slug = consultationStore.getInstanceSlug;
  const value = await Cookies.get(`wevaluate.${slug}`);
  return value;
};

const checkIsCookie = async () => {
  const cookie = await getCookie();
  isCookie.value = !!cookie;
  if (cookie) {
    analyticsStore.trackSystemAction(['Show warning', 'Repeated submission']);
  }
};

const updateData = (data) => {
  nextDisabled.value = data.user_has_answered === false;

  if (data.answer_id !== null) {
    showUserInputIsRequired.value = data.user_has_answered === false;
  }

  const payload = {
    stageId: consultationStore.stageId,
    data: data,
  };

  consultationStore.setAnswerData(payload);
};

onMounted(() => {
  checkIsCookie();
  nextDisabled.value = true;
});

</script>

<template>
  <div class="page-container">
    <CardPage>
      <section
        v-if="!getMobileView"
        class="navigation-wrapper row mb-4 ms-0 me-0"
      >
        <AppNavigation :navigation-panel-top="true" />
      </section>
      <div class="page-title">
        <h1>
          <LanguageSelector
            v-if="title"
            :to-be-selected="title"
          />
        </h1>
      </div>
      <div class="page-content">
        <LanguageSelector
          v-if="content"
          :to-be-selected="content"
        />
      </div>
      <div class="page-consent-wrapper">
        <InputTypeRadioTable
          ref="options"
          :current-question-data="consentOptions"
          :show-user-input-is-required="showUserInputIsRequired"
          :current-question-number="0"
          :current-question-display-number="1"
          :current-user-data="currentUserData"
          :validation-label="$t('pagination.consent_validation_no_selection_message')"
          @send-current-user-data="updateData"
        />
        <div
          v-if="isCookie"
          class="checkcookie p-2 question-border"
          style="border-color: red"
        >
          {{ $t('pagination.cookies_cookie_detected_message') }}
        </div>
      </div>
      <div class="page-navigation">
        <AppNavigation :submit-function="submit" />
      </div>
    </CardPage>
  </div>
</template>
