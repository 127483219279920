<template>
  <div class="choice-task-container d-flex flex-nowrap">
    <div
      class="research-nav-list-outer-wrapper d-flex w-100 justify-content-center"
    >
      <div
        class="research-nav-list-inner-wrapper px-2 px-sm-3 px-md-4 px-lg-4"
        :class="{ 'w-100': getMobileView }"
      >
        <ChoiceTaskHeader />
        <div class="research-list-wrapper d-flex flex-column">
          <component
            :is="currentComponent"
          />

          <div class="research-bottom-section-wrapper mt-4">
            <div
              :class="getMobileView ? 'justify-content-start' : 'justify-content-end'"
              class="d-flex mb-1"
            >
              <ChoiceTaskSkipCheckbox v-if="allowSkipChoiceTask" />
            </div>

            <AppNavigation
              v-if="!getMobileView"
              :in-choice-task="true"
              :submit-function="submit"
              class="mb-5"
            />
            <ChoiceTaskNavigationMobile
              v-else
              :submit-function="submit"
            />
          </div>
        </div>
      </div>
    </div>

    <ChoiceTaskDashboardDrawer
      v-if="getMobileView && showDashboard"
    />

    <ResearchDashboardDesktop
      v-else-if="showDashboard"
      class="ms-auto me-0"
    />

    <OptionDetailModal :show="showOptionDetailModal !== false" />
    <ChoiceTaskModalExceededRestrictions :show="showExceededRestrictionsModal" />
    <ChoiceTaskModalExceededWarning :show="showExceededWarningsModal" />
    <ChoiceTaskModalPointsLeft :show="showPointsLeftModal" />
    <ChoiceTaskModalTargetNotReached :show="showTargetNotReachedModal" />
    <ChoiceTaskModalTargetOverReached :show="showTargetOverReachedModal" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ResearchDashboardDesktop from './ResearchDashboardDesktop.vue';
import ChoiceTaskDashboardDrawer from './ChoiceTaskDashboardDrawer.vue';
import AppNavigation from './AppNavigation.vue';
import ChoiceTaskSkipCheckbox from './ChoiceTaskSkipCheckbox.vue';
import ChoiceTaskNavigationMobile from './ChoiceTaskNavigationMobile.vue';
import ChoiceTaskCompareMode from './ChoiceTaskCompareMode.vue';
import OptionDetailModal from './OptionDetailModal.vue';
import ChoiceTaskModalExceededRestrictions from './ChoiceTaskModalExceededRestrictions.vue';
import ChoiceTaskModalExceededWarning from './ChoiceTaskModalExceededWarning.vue';
import ChoiceTaskModalPointsLeft from './ChoiceTaskModalPointsLeft.vue';
import ChoiceTaskModalTargetNotReached from './ChoiceTaskModalTargetNotReached.vue';
import ChoiceTaskSelectionModes from './ChoiceTaskSelectionModes.vue';
import ChoiceTaskModalTargetOverReached from './ChoiceTaskModalTargetOverReached.vue';
import ChoiceTaskHeader from './ChoiceTaskHeader.vue';
import { mapState, mapWritableState } from 'pinia';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    AppNavigation,
    ChoiceTaskNavigationMobile,
    ChoiceTaskHeader,
    ChoiceTaskSelectionModes,
    ResearchDashboardDesktop,
    ChoiceTaskDashboardDrawer,
    ChoiceTaskCompareMode,
    OptionDetailModal,
    ChoiceTaskModalExceededRestrictions,
    ChoiceTaskModalExceededWarning,
    ChoiceTaskModalPointsLeft,
    ChoiceTaskModalTargetNotReached,
    ChoiceTaskModalTargetOverReached,
    ChoiceTaskSkipCheckbox,
  },

  setup() {
    const consultationStore = useConsultationStore();
    return { consultationStore };
  },

  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),

    ...mapWritableState(useConsultationStore, [
      'nextDisabled',
      'inCompareMode',
      'sortingProperty',
      'skipChoiceTask',
      'showOptionDetailModal',
      'showExceededRestrictionsModal',
      'showExceededWarningsModal',
      'showPointsLeftModal',
      'showTargetNotReachedModal',
      'showTargetOverReachedModal',
    ]),

    ...mapState(useConsultationStore, [
      'stage',
      'stageId',
      'stageOptions',
      'stageProperties',
      'getAnswerData',
      'isSliderMode',
      'isPointsMode',
    ]),

    allowSkipChoiceTask() {
      return this.stage.fields.allowSkipChoiceTask;
    },

    getExactTargetProperty() {
      if (!this.isSliderMode) {
        return false;
      }

      return this.stageProperties.find(
        ({ id }) => id === this.stage.fields.slideToMaxData.exact_target_property
      );
    },

    currentComponent() {
      if (this.inCompareMode) {
        return ChoiceTaskCompareMode;
      }

      return ChoiceTaskSelectionModes;
    },

    showDashboard() {
      if (this.isPointsMode) {
        return true;
      }

      if (this.getExactTargetProperty) {
        return true;
      }

      return this.stageProperties.length > 0;
    },
  },

  created() {
    this.inCompareMode = false;
    this.sortingProperty = null;
    this.skipChoiceTask = false;

    const answerData = this.getAnswerData[this.stageId].answers;
    const hasAnswered = Object.values(answerData).some((answer) => answer.quantity > 0);

    if (this.stage.fields.allowNoSelection || this.isPointsMode || this.isSliderMode || hasAnswered) {
      this.nextDisabled = false;
    } else {
      this.nextDisabled = true;
    }
  },

  methods: {
    submit() {
      if (this.skipChoiceTask) {
        this.consultationStore.submitChoiceTaskAnswerData(this.stageId);
        return true;
      }

      if (this.nextDisabled) {
        return false;
      }

      if (this.isPointsMode) {
        const target = this.stage.fields.pointsData.points_target;
        const answers = this.getAnswerData[this.stageId];

        if (answers === undefined) {
          this.showPointsLeftModal = true;
          return false;
        }

        if (answers !== undefined) {
          const totalPoints = Object.values(answers.answers).reduce((acc, answer) => {
            return acc + answer.quantity;
          }, 0);

          if (totalPoints < target) {
            this.showPointsLeftModal = true;
            return false;
          }
        }
      }

      if (this.getExactTargetProperty) {
        const currentValue = this.getExactTargetProperty.current_calculated_value;
        const exactTargetValue = this.stage.fields.slideToMaxData.exact_target_property_value;

        if (currentValue < exactTargetValue) {
          this.showTargetNotReachedModal = true;
          return false;
        }

        if (currentValue > exactTargetValue) {
          this.showTargetOverReachedModal = true;
          return false;
        }
      }

      const exceeded = this.stageProperties.some((p) => {
        return p.current_calculated_value_exceeds_restriction === 'max' || p.current_calculated_value_exceeds_restriction === 'min';
      });

      if (exceeded) {
        this.showExceededRestrictionsModal = true;
        return false;
      }

      const warning = this.stageProperties.some((p) => {
        return p.current_calculated_value_exceeds_restriction === 'flex-max' || p.current_calculated_value_exceeds_restriction === 'flex-min';
      });

      if (warning && this.stage.fields.showWarningPopup === true) {
        this.showExceededWarningsModal = true
        return false;
      }

      this.consultationStore.submitChoiceTaskAnswerData(this.stageId);
      this.consultationStore.processConditions();
    },
  },
});
</script>

<style scoped>
.choice-task-container {
  min-height: 100vh;
}

.research-nav-list-outer-wrapper {
  max-width: 100vw;
}

@media (min-width: 1000px) {
  .research-nav-list-inner-wrapper {
    width: 600px;
  }
}

@media (min-width: 1024px) {
  .research-nav-list-inner-wrapper {
    width: 712px;
  }
}

@media (min-width: 1440px) {
  .research-nav-list-inner-wrapper {
    width: 1000px;
  }
}

@media (min-width: 2560px) {
  .research-nav-list-inner-wrapper {
    width: 1500px;
  }
}

.research-list-wrapper {
  -webkit-overflow-scrolling: touch;
  z-index: 1;
  margin-top: 1rem;
}

@media (min-width: 1000px) {
  .research-list-wrapper {
    margin-top: 2rem;
  }
}
</style>
