import { BlockQuestions, Question, QuestionCheckboxTable, QuestionDragInOrder, QuestionDropdown, QuestionMatrix, QuestionRadioAB, QuestionRadioTable, QuestionSlider, QuestionText, QuestionTextArea, QuestionTextAreaLarge, QuestionTextSection } from "../../../types/BlockQuestions";
import { StageSurvey } from "../../../types/StageSurvey";

export function SurveyAdapter(data: BlockQuestions): StageSurvey[] {
  if (data.stages.length === 0) {
    return [];
  }

  const stages = [];

  for (const [index, stage] of data.stages.entries()) {
    const questions = stage.questions.map(adaptQuestion).filter((q) => q !== null);

    if (questions.length === 0) {
      continue;
    }

    stages.push({
      id: `survey-${data.id}-${index}`,
      stageId: data.id,
      stageType: 'survey',
      blockId: data.id,
      blockType: data.block_type,
      component: 'SegmentSurvey',
      conditions: data.conditions,
      hidden: false,
      fields: {
        title: stage.title,
        subtitle: stage.subtitle,
        questions: questions,
      }
    });
  }

  return stages;
}

function adaptQuestion(question: Question) {
  switch (question.field_type) {
    case 'text':
      return adaptTextQuestion(question as QuestionText);
    case 'text_area':
      return adaptTextAreaQuestion(question as QuestionTextArea);
    case 'text_area_large':
      return adaptTextAreaLargeQuestion(question as QuestionTextAreaLarge);
    case 'slider':
      return adaptSliderQuestion(question as QuestionSlider);
    case 'radio_table':
      return adaptRadioTableQuestion(question as QuestionRadioTable);
    case 'radio_ab':
      return adaptRadioABQuestion(question as QuestionRadioAB);
    case 'checkbox_table':
      return adaptCheckboxTableQuestion(question as QuestionCheckboxTable);
    case 'dropdown':
      return adaptDropdownQuestion(question as QuestionDropdown);
    case 'matrix':
      return adaptMatrixQuestion(question as QuestionMatrix);
    case 'text_section':
      return adaptTextSectionQuestion(question as QuestionTextSection);
    case 'drag_in_order':
      return adaptDragInOrderQuestion(question as QuestionDragInOrder);
    default:
      // Sentry capture: new UnknownQuestionTypeError(type);
      return null;
  }
}

function adaptTextQuestion(question: QuestionText) {
  return {
    id: question.id,
    required: question.required,
    field_type: question.field_type,
    title: question.title,
    conditions: question.conditions,
    messages: question.messages,
    hidden: false,
    component: 'InputTypeText',
    raw_input_text: null,
    user_answered_string: null,
    user_has_answered: false,
  }
}

function adaptTextAreaQuestion(question: QuestionTextArea) {
  return {
    id: question.id,
    required: question.required,
    field_type: question.field_type,
    title: question.title,
    conditions: question.conditions,
    messages: question.messages,
    max_characters_allowed: question.max_characters_allowed,
    hidden: false,
    component: 'InputTypeTextArea',
    raw_input_text: null,
    user_answered_string: null,
    user_has_answered: false,
  }
}

function adaptTextAreaLargeQuestion(question: QuestionTextAreaLarge) {
  return {
    id: question.id,
    required: question.required,
    field_type: question.field_type,
    title: question.title,
    conditions: question.conditions,
    messages: question.messages,
    max_characters_allowed: question.max_characters_allowed,
    hidden: false,
    component: 'InputTypeTextAreaLarge',
    raw_input_text: null,
    user_answered_string: null,
    user_has_answered: false,
  }
}

function adaptSliderQuestion(question: QuestionSlider) {
  return {
    id: question.id,
    required: question.required,
    field_type: question.field_type,
    title: question.title,
    conditions: question.conditions,
    messages: question.messages,
    scale: question.scale,
    description: question.description,
    hidden: false,
    component: 'InputTypeSlider',
    raw_input_slider: null,
    user_answered_string: null,
    user_has_answered: false,
  }
}

function adaptRadioTableQuestion(question: QuestionRadioTable) {
  return {
    id: question.id,
    required: question.required,
    field_type: question.field_type,
    title: question.title,
    conditions: question.conditions,
    messages: question.messages,
    answer_options: question.answer_options,
    hidden: false,
    component: 'InputTypeRadioTable',
    raw_input_radio_table: null,
    user_answered_string: null,
    user_has_answered: false,
    answer_id: null,
  }
}

function adaptRadioABQuestion(question: QuestionRadioAB) {
  return {
    id: question.id,
    required: question.required,
    field_type: question.field_type,
    title: question.title,
    conditions: question.conditions,
    messages: question.messages,
    answer_options: question.answer_options,
    hidden: false,
    component: 'InputTypeRadioAb',
    raw_input_radio_ab: null,
    user_answered_string: null,
    user_has_answered: false,
    answer_id: null,
  }
}

function adaptCheckboxTableQuestion(question: QuestionCheckboxTable) {
  return {
    id: question.id,
    required: question.required,
    field_type: question.field_type,
    title: question.title,
    conditions: question.conditions,
    messages: question.messages,
    answer_options: question.answer_options,
    open_answer: question.open_answer,
    hidden: false,
    component: 'InputTypeCheckboxTable',
    user_has_answered: false,
    user_answered_string: null,
    user_answered_array: null,
    raw_input_check_box_open_answer: false,
    raw_input_check_box_table: {},
    raw_input_text: null,
    answer_id: [],
  }
}

function adaptDropdownQuestion(question: QuestionDropdown) {
  return {
    id: question.id,
    required: question.required,
    field_type: question.field_type,
    title: question.title,
    conditions: question.conditions,
    messages: question.messages,
    answer_options: question.answer_options,
    hidden: false,
    component: 'InputTypeDropdown',
    raw_input_dropdown: null,
    user_answered_string: null,
    user_has_answered: false,
    answer_id: null,
  }
}

function adaptMatrixQuestion(question: QuestionMatrix) {
  question.matrix_theses.forEach((thesis) => {
    thesis.hidden = false;
  });

  return {
    id: question.id,
    required: question.required,
    field_type: question.field_type,
    title: question.title,
    conditions: question.conditions,
    messages: question.messages,
    answer_options: question.answer_options,
    matrix_theses: question.matrix_theses,
    hidden: false,
    component: 'InputTypeMatrix',
    user_has_answered: false,
    user_answered_array: null,
    user_has_answered_array: null,
    raw_input_matrix_table: {},
    answer_id: [],
  }
}

function adaptTextSectionQuestion(question: QuestionTextSection) {
  return {
    id: question.id,
    required: false,
    field_type: question.field_type,
    title: question.title,
    messages: question.messages,
    description: question.description,
    conditions: question.conditions,
    hidden: false,
    component: 'InputTypeTextSection',
  }
}

function adaptDragInOrderQuestion(question: QuestionDragInOrder) {
  return {
    id: question.id,
    required: question.required,
    field_type: question.field_type,
    title: question.title,
    conditions: question.conditions,
    messages: question.messages,
    randomize_answer_order: question.randomize_answer_order,
    answer_options: question.answer_options,
    hidden: false,
    component: 'InputTypeDragInOrder',
    user_has_answered: false,
    user_answered_array: null,
    raw_ordered_answers: null,
    answer_id: [],
  }
}
